import { BaseAnnotatedTextData } from './base-annotated-text-data';
import { StoryElement } from './story-element';

type InterviewQuestion = {
  type: 'question';
  props: BaseAnnotatedTextData;
};
type InterviewAnswer = {
  type: 'answer';
  props: BaseAnnotatedTextData;
};
type InterviewItem = {
  id: string;
} & (InterviewQuestion | InterviewAnswer);

export type InterviewData = {
  items: InterviewItem[];
};

export type InterviewStoryElement = StoryElement<'interview', InterviewData>;

export function isInterviewElement(storyElement?: StoryElement | null): storyElement is InterviewStoryElement {
  return !!storyElement && storyElement.type === 'interview';
}
